import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const ProductBeforeHeat = () => (
  <Layout>
    <SEO
      title="Apply Product Before Heat For Healthy Hair"
      desciption="The best way to avoid damage from heat is not to use heat at all. With that being said, heat is essential for achieving certain styles. Despite our name, we are not against heat or making changes to your hair"
    />
    <h1>Apply Product Before Heat For Healthy Hair</h1>

    <p>The best way to avoid damage from heat is not to use heat at all. With that being said, heat is essential for achieving certain styles. Despite our name, we are not against heat or making changes to your hair. We just think it’s important that you should be educated and do things as safely as possible to have your hair as healthy as it can be.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/D373rVF.jpg"
        alt="Apply Product Before Heat For Healthy Hair"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@obasile"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Olivia Basile</a></p>
    </div>

    <p>Heat temporarily changes the structure of the hair. Depending on how much heat you apply, you can get rid of your curl pattern completely so your hair is straight. This is the desired effect for most people.</p>
    <p>Most of the time, regular conditioners are leave-in conditioners simply aren’t enough. I mentioned how  heat can temporarily change the structure of your hair but repeated exposure to heat and lack of care will cause heat damage and permanent changes. This means your hair won’t go back to its original state and your only options will be to let the damaged hair grow out and cut as it grows or just cut it all off in one go.</p>
    <p>The alternative, and something most people end up doing, is live a life of constant upkeep. Always applying heat to keep it looking a certain way. Again, this isn’t necessarily a bad thing but there are some things you can do to keep your hair healthy to reduce further damage and get your hair looking great.</p>

    <h2>Applying Before</h2>
    <p>The right product applied before heat can act as a barrier and lubricant to protect the hair from damage. When you heat something up, you naturally heat up the moisture within it and it starts to evaporate if it reaches a high enough temperature. You can tell this happens when you apply heat because you can see the steam rising. That steam is the water content in your hair or moisture. Less moisture means less protection. No moisture and you might find the heating element sticks to your hair and causes real damage.</p>
    <p>Oils and conditioner seal the moisture and create a barrier between the heat surface and the hair. Your hair gets all the benefits of the heat with far less chance of physical damage caused by heat. The heat surface will glide much better and shouldn’t stick to or burn your hair.</p>

    <h2>Deep Condition</h2>
    <p>If you don’t already deep condition your hair, you might have heard that other people do. If not, it’s a way of restoring health to your hair by letting highly nutritious products (often homemade) sit on the hair and giving it time to sink in. It’s usually protein rich because hair responds very well to protein and you can often see the effects straight away.</p>
    <p>This fast acting treatment is what helps create added protection against the heat you are about to apply. A lot of people use deep conditioners as a way to add the nutrients lost to daily exposure to the air. You can use deep conditioning for this reason but also to protect against heat. It’s good because as someone who applies heat to their hair, you need it more than anyone.</p>

    <h2>Specialised Conditioners</h2>
    <p>What if you aren’t interested in a deep conditioner and you don’t have all of that time to invest? Because the deep conditioning process can really take a long time. The good news is that you don’t have to sit through that process and you can go with a specialised leave-in or wash out conditioner. They have ingredients that do a great job of protecting against heat. If you don’t want to buy more products than you already have. You can generally apply more conditioner than you normally would to create more of a barrier.</p>
    <p>Look around for conditioning creams, styling treatments, protection creams, they go by all sorts of different names and work just as we’ve already discussed. Not to be confused with straightening conditioners that are designed to sit on the hair and weigh it down to achieve the look. You don’t have to be a genius to realise that those products aren’t going to work on anything beyond moderately wavy hair types.</p>
    <p>Most recommend you work into your hair after you have gently towel dried. Some suggest blow drying first. Then you flat iron as usual. You should end up with straight hair with a visible shine that has been protected. Another thing to look out for is that some conditioners are only meant to be used on dry hair. It just means that for the best results, you should use them after blow drying and before flat ironing.</p>

    <h2>Heat Care</h2>
    <p>When I talk about heat, it’s mainly using a hair dryer and hair straighteners. Heat does have the potential to cause damage but there are definitely some things you can do to give yourself a better chance of suffering that damage.</p>
    <p>Use good quality products. Hair dryers come in all shapes and sizes but one of the things to look out for is multiple heat settings. A blow dryer should have at least 3 settings; cool medium and hot. You might find that the medium setting is all you need to get the job done. Don’t be tempted to put it on the highest setting to get done faster. That way the ends of your hair might end up crispy before the roots are even dry.</p>
    <p>When it comes to straighteners, the material of the plates makes a difference. Poor quality plates will lose their non-stick after a while and you might not even notice before it’s too late. In the same way a clothes iron can collect material and go black over time then stop working as well. Some of the higher price straighteners have a steam function to add hydration as you go, giving your hair even more protection against drying out.</p>
    <p>Lastly, for aftercare, remember heat treated hair hair still needs to be wrapped up at night and cared for. It would be pointless to put all this effort in beforehand then just leave it to its own devices. Put proper care in and your hair will continue to look beautiful.</p>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default ProductBeforeHeat
